import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="QR Object">
      <div className="headline">
        <h1 className="text-primary m-0">QR Object</h1>
      </div>
      <div className="items-group">
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">type</span>{" "}
            <span className="field-type block-md">String</span>
          </div>

          <div className="col-md-9">
            <p>
              Value fixed to<span className="reference">qrdata</span>, represents QR
              object
            </p>
          </div>
        </div>

        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">qr_url</span>{" "}
            <span className="field-type block-md">String</span>
          </div>

          <div className="col-md-9">
            <p>URI of QR image</p>
          </div>
        </div>

        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">qr_value</span>{" "}
            <span className="field-type block-md">String</span>
          </div>

          <div className="col-md-9">
            <p>Value of QR</p>
          </div>
        </div>
      </div>
    </DocumentLayout>
  )
}
